<template lang="pug">
v-form
  v-row
    v-col( :md="!isCustomer ? 3 : 4" )
      DatePicker( v-model="dateStart" label="Дата начала" )
    v-col( :md="!isCustomer ? 3 : 4" )
      DatePicker( v-model="dateEnd" label="Дата окончания" )
    v-col( :md="!isCustomer ? 3 : 4" )
      v-select( v-model="project" item-text="name" item-value="id" :items="projects" label="Проект" )
    v-col( :md="!isCustomer ? 3 : 4" )
      v-autocomplete( v-model="city" v-if="!isCustomer" label="Город" multiple chips :items="cities" )
  v-row
    v-col
      v-btn( :disabled="!valid" @click="load") Найти
  v-row
    v-col
      v-data-table( class="elevation-1" :headers="headers" :items="reports" :loading="loading" loading-text="Загружается... Пожалуйста подождите" )
        template( v-slot:item.name="{ item }" )
          router-link( :to="{ name: 'downloaditem', params: { project: project, id: item.id, start: dateStart, end: dateEnd, cities: city.join() } }" ) {{ item.name }}
        template( v-slot:item.count="{ item }" ) {{ item.points }} / {{ item.reports }}
        template( v-slot:item.action="{ item }" )
          Export( v-if="item.reports > 0" small :zip="getZip(item.id)" )

</template>


<script>
import { projectService, networkService, reportService, tradepointService } from '@/_services'
import { mapMutations, mapState, mapGetters } from "vuex";
import Export from './Export'
import DatePicker from './DatePicker';

export default {
  components: {
    Export,
    DatePicker
  },

  data: () => ({
    dateStart: new Date().toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),
    modal: false,
    project: 0,
    city: [],
    headers: [
      { text: 'Название сети', value: 'name' },
      { text: 'Кол-во точек / Кол-во отчётов', value: 'count' },
      { text: 'Действия', value: 'action' }
    ],
    reports: [],
    loading: false,
    cities: []
  }),

  mounted() {
    this.loadProjects()
  },

  
  watch: {
    project: function(val) {
      this.loadCities()
    },
  },

  computed: {
    ...mapState("project", ["projects"]),
    ...mapGetters("authentication", ["checkRole"]),

    isCustomer() {
      return this.checkRole(0)
    },

    valid() {
      return this.project != 0
    },
  },

  methods: {
    ...mapMutations("project", ["setProjects"]),

    loadProjects() {
      if (this.projects.length > 0) return
      projectService.list().then(data => {
        this.setProjects(data)
        if (data.length > 0)
          this.project = data[0].id
      })
    },

    load() {
      this.loading = true
      networkService.loadForReport(this.project, this.dateStart, this.dateEnd, this.city.join()).then(data => {
        this.reports = data;
      }).finally(() => this.loading = false)
    },

    loadCities() {
      tradepointService.loadCities(this.project, []).then(data => this.cities = data)
    },

    getZip(id) {
      return {
        method: reportService.excelByNetworkLink,
        id: id,
        params: {
          project_id: this.project,
          start: this.dateStart,
          end: this.dateEnd,
          cities: this.city.join()
        }
      }
    }
  }
}
</script>